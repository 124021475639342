import cn from "classnames";
import { useState } from "react";

import { useDealEventProperties } from "../../organisms/deal-event-properties/use-cases/deal-event-properties-use-cases";
import { trackChoice } from "../../utils/analytics";
import * as styles from "./RadioFieldset.module.scss";

export enum RadioFieldSetVariantsName {
  healthProductSelector = "healthProductSelector",
  smallPrintPages = "smallPrintPages",
  petSpeciesSelector = "petSpeciesSelector",
}

interface RadioFieldsetProps {
  name: string;
  options: Array<Record<string, any>>;
  legend: string;
  initialValue: unknown;
  onChange?: (value: unknown) => void;
  variantName?: RadioFieldSetVariantsName;
  extraEventProperties?: Record<string, string>;
}

const RadioFieldset = ({
  name,
  options,
  legend,
  initialValue,
  onChange,
  variantName,
  extraEventProperties = {},
}: RadioFieldsetProps): JSX.Element => {
  const componentName = "ml-radio-fieldset";
  const dealEventProperties = useDealEventProperties() as Record<string, any>;
  const [currentValue, setCurrentValue] = useState(initialValue);

  return (
    <div
      className={cn(styles.radioFieldset, componentName, {
        [styles[variantName as string]]: variantName,
      })}
      role="group"
      aria-label={legend}
    >
      {options.map((option, index) => {
        const id = `${name}-${index}`;
        const { value, title, subtitle, description, labelVariantClassName, image } = option;

        return (
          <div className={cn("form-control")} key={index}>
            <input
              type="radio"
              name={name}
              id={id}
              value={value}
              checked={value === currentValue}
              onChange={(e) => {
                setCurrentValue(value);
                trackChoice(e, { ...dealEventProperties, ...extraEventProperties });
                onChange && onChange(value);
              }}
            />
            <label
              htmlFor={id}
              className={cn({
                [styles[labelVariantClassName as string]]: labelVariantClassName,
              })}
            >
              {image && <span className={cn(styles.image, "form-control-image")}>{image}</span>}

              <span className={cn(styles.title, "form-control-title")}>{title}</span>

              {subtitle && (
                <span className={cn(styles.subtitle, "form-control-subtitle")}>{subtitle}</span>
              )}

              {description && (
                <span className={cn(styles.description, "form-control-description")}>
                  {description}
                </span>
              )}

              <svg className={cn(styles.icon, "icon")} role="img" aria-hidden="true">
                <use
                  href={
                    labelVariantClassName === "incorrectAnswer"
                      ? "#icon-cross"
                      : variantName === RadioFieldSetVariantsName.petSpeciesSelector
                      ? "#icon-thickness-check"
                      : "#icon-white-check"
                  }
                ></use>
              </svg>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default RadioFieldset;
